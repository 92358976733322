.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-body {
  position: relative;
  background-color: #004; 
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white; 
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-display-img-sm{
  position: relative;

  height: 10vmin;
}

.file-display-img-med{
  position: relative;

  height: 20vmin;
}

.file-display-img-large{
  margin: auto;
  position: relative;
  height: 30vmin;
}


.file-display{
  position: relative;
  margin: auto;
  font-size: 10pt;
}


.input-file-label{
  font-size: 10pt;
}

.input-file{
  position: relative;
  margin: auto;
  font-size: 10pt;
}


.small-font{
  
  font-size: 8pt;
  margin: auto;
}

.mid-font{
  position: absolute;
  font-size: 10pt;
  margin: auto;
}

.mouse-image{
  position: absolute;
  top: 16px;
  left: 128px;
  background-color: white;
  width: 256px;
  height: 256px;
  border: 1px solid #f00;
  margin: auto;
}

.mouse-box{
  position: relative;
  width: 512px;
  height: 300px;
  border: 2px solid #f00;
  padding: 2px;
  margin: 2px;
}

.sidebar{
  position: absolute;
  right: 10px;
  top: 16px;
  width: 100px;
  height: 80%;
  border: 2px solid #f00;
}

.sidebar2{
  position: absolute;
  right: 10px;
  top: 16px;
  width: 100px;
  height: 40%;
  border: 2px solid #f00;
}

.button-sb1{
  position: absolute;
  right: 5px;
  top: 5px;
}

.button-sb2{
  position: absolute;
  right: 5px;
  top: 190px;
}


.stroke-width{
  position: absolute;
  right: 5px;
  top: 20px;
}

.back-color{
  position: absolute;
  right: 5px;
  top: 65px;
}

.pen-color{
  position: absolute;
  right: 5px;
  top: 120px;
}

.fi1{
  position: absolute;
  font-size: 8pt;
  right: 2px;
  top: 10px;
  width: 90px;
}

.fi2{
  position: absolute;
  right: 5px;
  top: 30px;
  width: 35px;
}

.fi3{
  position: absolute;
  right: 2px;
  top: 30px;
  width: 65px;
}

.btn-controls{
  position: relative;
  width: auto;
  height: auto;
  padding-top: 0.25em;
  padding-bottom: 3em;
  margin: auto;
  padding-left: 0.25em;
  padding-right: 0.25em;
  border: 2px solid #f00;
}

.img-proc-request-buttons{
   position: relative;
   top: 45px;
   height: auto;
   width: auto;
   padding-top: 0.25em;
   padding-bottom: 0.75em;
   padding-left: 0.25em;
   padding-right: 0.25em;
   margin: auto;
   border: solid 2px #800; 
}

.proc-img-block{
   position: relative;
   top: 45px;
   height: auto;
   width: auto;
   padding-top: 0.25em;
   padding-bottom: 0.75em;
   padding-left: 0.25em;
   padding-right: 0.25em;
   margin: auto;
}


.cmd-editor{
   position: relative;
   top: 45px;
   height: auto;
   width: auto;
   padding-top: 0.25em;
   padding-bottom: 0.75em;
   padding-left: 0.25em;
   padding-right: 0.25em;
   margin: auto;
   border: solid 2px #800;
}


.mod-button-enable{
  position: absolute;
  left: 0.5em ;
  top: 25px;
  background-color: #ddd;
}

.mod-button-disable{
  position: absolute;
  left: 0.5em;
  top: 25px;
  background-color: #666;
}

.class-button-enable{
  position: absolute;
  left: 7em;
  top: 25px;
  background-color: #ddd;
}


.class-button-disable{
  position: absolute;
  left: 7em;
  top: 25px;
  background-color: #666;
}

.proc-button-enable{
  position: absolute;
  left: 14em;
  top: 25px;
  background-color: #ddd;
}


.proc-button-disable{
  position: absolute;
  left: 14em;
  top: 25px;
  background-color: #666;
}

.input-file-label {
  position: relative;
  margin-left: 5px;
  width: auto;
  left: 2em;
}

.input-file {
  position: relative;
  width: auto;
  margin: auto;
  height: auto;
}

.text-command-block{
  
  position: relative;
  width: 512px;
  height: 200px;
  border: 2px solid #f00;
  padding: 2px;
  margin: 2px;
}

.lisp-block{
  position: relative;
  width: 80%;
  height: 40vh;
  border: 2px solid #f00;
  padding: 2px;
  margin: 2px;
}
.lisp-output{
  position: relative;
  width: 80%;
  height: 40vh;
  border: 2px solid #f00;
  padding: 2px;
  margin: 2px;
}




.mouse-box{
  position: relative;
  width: 512px;
  height: 300px;
  border: 2px solid #f00;
  padding: 2px;
  margin: 2px;
}

.cmd-text {
  position: absolute;
  width:  75%;
  height: 80%;
  left: 10px;
  top: 2.5em;
}
